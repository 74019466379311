import { RuntimeConfig } from '../../../infrastructure/lib/runtime-config';

declare global {
  interface Window {
    CONFIG: RuntimeConfig;
  }
}

// New modules must also be defined in infrastructure/lib/runtime-config.ts
// This config is used when running locally, so URLS will generally point to a
// 'shared' instance of other services.
// This could be extended with REACT_... env variables if you wanted to
// override them to your own instances (e.g. of akumen or auth)
export const localRuntimeConfig: RuntimeConfig = {
  auth: {
    api: 'https://auth.shared.dev.phobos-cloud.com',
  },
  akumen: {
    api: 'https://akumen.shared.dev.phobos-cloud.com',
  },
  modules: {
    gemini: {
      url: 'https://gemini.shared.dev.phobos-cloud.com',
      enabled: true,
      visible: true,
    },
    mpn: {
      url: 'https://mpn.shared.dev.phobos-cloud.com',
      enabled: true,
      visible: true,
    },
    ddm: {
      url: 'https://ddm.shared.dev.phobos-cloud.com',
      enabled: true,
      visible: true,
    },
    better_bidding: {
      url: 'https://better-bidding.shared.dev.phobos-cloud.com',
      enabled: true,
      visible: true,
    },
  },
};

// return config for the environment we are compiled for
const runtimeConfig = process.env.NODE_ENV === 'development' ? localRuntimeConfig : window.CONFIG;
export const CONFIG = runtimeConfig as RuntimeConfig;
